import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';
import { useContext } from "react";
import { ShopContext } from "../context/shop-context";

const BreadCrumb = ({crumbs}) => {

    const { handleFilterChange } = useContext(ShopContext);
    const navigate = useNavigate();

    return (
        <Breadcrumbs aria-label="breadcrumb" 
            sx={{ 
                '& .MuiBreadcrumbs-ol': {
                    justifyContent: { 
                        xs: 'center', 
                        sm: 'center',
                        md: 'flex-start'
                    }},
                marginBottom: '20px'
            }} 
            >
            {crumbs && crumbs.map(crumb => {
                return (
                    <Link key={crumb} sx={{ cursor: 'pointer' }} underline="hover" color="inherit" onClick={() => { handleFilterChange(crumb); navigate('/products')}}>
                        {crumb}
                    </Link>
                )
            })}
        </Breadcrumbs>
    )
}

export default BreadCrumb;
