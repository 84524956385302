import React from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const ProductManagement = ({ data, clickHandler }) => {

    const {id, name, price, imageUrl, categoryName, subCategoryName} = data;

    return (
        <Card sx={{ textAlign: 'center', cursor: 'pointer', height: '100%' }} onClick={clickHandler}>
            <CardMedia
                sx={{ height: 150, objectFit: 'scale-down' }}
                component="img"
                alt="Product image"
                image={imageUrl}
            />
            <CardContent>
                <Typography variant="body2" color="text.secondary">
                    {price} &#8364;
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {categoryName}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {subCategoryName}
                </Typography>
                <Typography gutterBottom variant="h6" component="div">
                    {name}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default ProductManagement;