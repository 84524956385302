import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="fixed">
      <Link to="/privacy-policy"> Política de Privacidade </Link>
      <Link to="/cookie-policy"> Política de Cookies </Link>
      <Link to="/personal-data-policy"> Política de Dados Pessoais </Link>
      <Link to="/conditions-of-use"> Termos e Condições de Utilização </Link>
      <Link to="/contacts"> Contactos </Link>
    </footer>
  );
};

export default Footer;