import axios from "axios";
import { API, Auth } from 'aws-amplify';

export async function fetchCategories () {
    try {
      const URL = 'https://h2mcmvayu9.execute-api.us-east-1.amazonaws.com/stg/categories';
      const response = await axios.get(URL);
      return response;
    } 
    catch(error) {
      console.log(error);
    }
}

export async function fetchProducts () {
    try {
      const URL = 'https://fs0lfmkkq4.execute-api.us-east-1.amazonaws.com/stg/products';
      const response = await axios.get(URL);
      return response;
    } 
    catch(error) {
      console.log(error);
    }
}

export async function fetchManagementCategories () {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const token = user.signInUserSession.idToken.jwtToken;
      const myInit = { headers: { Authorization: `Bearer ${token}` } };
      const response = await API.get('management', '/categories', myInit);
      return response;
    } 
    catch(error) {
      console.log(error);
    }
}

export async function fetchManagementProducts () {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const token = user.signInUserSession.idToken.jwtToken;
      const myInit = { headers: { Authorization: `Bearer ${token}` } };
      const response = await API.get('management', '/products', myInit);
      return response;
    } 
    catch(error) {
      console.log(error);
    }
}

export async function postCategory ({id, name, visible}) {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const myInit = {
      body: { id: id, name: name, visible: visible},
      headers: { Authorization: `Bearer ${token}` }
    };
  
    API.post('management', '/categories', myInit)
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error));
}

export async function postProduct ({id, name, price, imageUrl, subCategoryId}) {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const myInit = {
      body: { id: id === '' ? null : id, name: name, price: price, imageUrl: imageUrl, subCategoryId: subCategoryId },
      headers: { Authorization: `Bearer ${token}` }
    };
  
    API.post('management', '/products', myInit)
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error));
}

export async function delProduct (id) {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const myInit = {
    body: { id: id },
    headers: { Authorization: `Bearer ${token}` }
  };

  API.del('management', '/products', myInit)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
}

export async function postProductRelated ({id, related}) {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const myInit = {
    body: { id: id, productsIds: related },
    headers: { Authorization: `Bearer ${token}` }
  };

  API.post('management', '/products-related', myInit)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
}

export async function delCategory (id) {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const myInit = {
      body: { id: id},
      headers: { Authorization: `Bearer ${token}` }
    };
  
    API.del('management', '/categories', myInit)
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error));
}

export async function postSubCategory ({id, name, visible, categoryId}) {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const myInit = {
      body: { id: id === '' ? null : id, name: name, visible: visible, categoryId: categoryId},
      headers: { Authorization: `Bearer ${token}` }
    };
  
    API.post('management', '/sub-categories', myInit)
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error));
}