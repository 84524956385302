import { useState } from 'react';
import { useContext } from "react";
import { ShopContext } from "../context/shop-context";
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const MenuItem = ({ data }) => {

  const { name, subCategories } = data;
  const navigate = useNavigate();
  const { handleFilterChange } = useContext(ShopContext);
  const [dropdown, setDropdown] = useState(false);

  return (
    <Box sx={{ position: 'relative' }} onMouseEnter={() => setDropdown(true)} onMouseLeave={() => setDropdown(false)}>
      <Button sx={{ color: 'white' }} onClick={() => { handleFilterChange(name); navigate('/products');}}>
        {name}
      </Button>
      <Box sx={{ display: dropdown ? 'flex' : 'none', flexDirection: 'column', whiteSpace: 'nowrap', position: 'absolute', background: '#0071dc', color: 'white', minWidth: '150px', zIndex: 1 }}>
        {subCategories.map( subCategory => (
            <Button key={subCategory.id} onClick={() => { handleFilterChange(subCategory.name); navigate('/products');}} sx={{ color: 'white' }}> 
              {subCategory.name} 
            </Button>
          ))
        }
      </Box>
    </Box>
  );
};

export default MenuItem;