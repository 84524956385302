import { useQuery, useQueryClient } from "@tanstack/react-query";
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useState } from "react";
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import { useNavigate } from 'react-router-dom';
import Loading from "../components/Loading";
import { fetchManagementCategories, postCategory, delCategory } from "../api/requests";


const ManagementCategories = () => {

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false); // dialog
  const [loading, setLoading] = useState(false); // spinning bar
  const [alert, setAlert] = useState({show: false, severity: 'sucess', message: ''}); // feedback
  const [categoryToEdit, setCategory] = useState({name: '', visible: false});
  const { isLoading, isError, data } = useQuery(['management_categories'], fetchManagementCategories, { staleTime: Infinity });

  if(isLoading) return <Loading loading backdrop />
  if(isError) return (<div>Error</div>)

  const updateCategory = async ({id, name, visible}) => {
    setLoading(true);

    postCategory({id, name, visible})
      .then( _ => {
        setLoading(false);
        setAlert({show: true, severity: 'success', message: 'Categoria atualizada com sucesso'});
        queryClient.invalidateQueries(['management_categories'], {exact: true });
      })
      .catch( _ => {
        setLoading(false);
        setAlert({show: true, severity: 'error', message: 'Erro ao atualizar a categoria'});
      });
  }

  const deleteCategory = async (id) => {
    setLoading(true);
    delCategory(id)
      .then( _ => {
        setLoading(false);
        setAlert({show: true, severity: 'success', message: 'Categoria apagada com sucesso'});
        queryClient.invalidateQueries({  queryKey: ['management_categories'], exact: true });
      })
      .catch( _ => {
        setLoading(false);
        setAlert({show: true, severity: 'error', message: 'Erro ao apagar a categoria'});
      });
  }

  const handleClickOpen = (category) => {
    setCategory(category);
    setOpen(true);
  };

  const handleClickDelete = (id) => {
    deleteCategory(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    updateCategory(categoryToEdit);
    setOpen(false);
  };

  return (
    <div>
      <Loading loading={loading} backdrop />
      {alert.show ? <Alert severity={alert.severity} icon={<CheckIcon fontSize="inherit" />} onClose={() => setAlert({show: false, severity: 'sucess', message: ''})}> {alert.message} </Alert> : <></> }
      <Button onClick={() => navigate('/management')}>Voltar</Button>
      <Button onClick={() => handleClickOpen({id: null, name: '', visible: false})}>Adicionar Categoria</Button>
      <Button onClick={() => queryClient.invalidateQueries({  queryKey: ['management_categories'], exact: true })}>Atualizar</Button>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell> ID </TableCell>
              <TableCell> Nome </TableCell>
              <TableCell> Visível </TableCell>
              <TableCell> </TableCell>
              <TableCell> </TableCell>
              <TableCell> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((category) => (
              <TableRow key={category.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell> {category.id} </TableCell>
                <TableCell> {category.name} </TableCell>
                <TableCell> {category.visible ? 'Sim' : 'Não'} </TableCell>
                <TableCell> 
                  <Button variant="outlined" onClick={() => handleClickOpen(category)}>
                    Editar
                  </Button>
                </TableCell>
                <TableCell> 
                  <Button variant="outlined" onClick={() => handleClickDelete(category.id)}>
                    Apagar
                  </Button>
                </TableCell>
                <TableCell>
                <Button variant="outlined" onClick={() => navigate('/management/categories/'+category.id)}>
                    Detalhes
                </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{categoryToEdit.id === null ? 'Nova Categoria' : 'Editar Categoria'}</DialogTitle>
        <DialogContent>
          <FormGroup>
            <TextField onChange={(event) => { setCategory({...categoryToEdit, name: event.target.value}) }}
                autoFocus margin="dense" id="name" label="Nome" type="text" fullWidth variant="standard" defaultValue={categoryToEdit.name} />
            <FormControlLabel 
                control={<Checkbox checked={categoryToEdit.visible} onChange={(event) => setCategory({...categoryToEdit, visible: event.target.checked})  } />} 
                label="Visível" />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleSave}>Guardar</Button>
        </DialogActions>
      </Dialog>
    </div>
    
  );
    
};

export default ManagementCategories;



