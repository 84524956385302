import Product from '../components/Product';
import { useQuery } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { ShopContext } from "../context/shop-context";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useNavigate } from "react-router-dom";
import { fetchProducts } from "../api/requests";
import Loading from '../components/Loading';
import products from "../api/products.json";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import SortIcon from '@mui/icons-material/Sort';

const nameAsc = (p1, p2) => {
  const p1U = p1.name.toUpperCase(); // ignore upper and lowercase
  const p2U = p2.name.toUpperCase(); // ignore upper and lowercase
  if (p1U < p2U) { return -1; }
  if (p1U > p2U) { return 1; }
  return 0;
}
const nameDesc = (p1, p2) => {
  const p1U = p1.name.toUpperCase(); // ignore upper and lowercase
  const p2U = p2.name.toUpperCase(); // ignore upper and lowercase
  if (p1U < p2U) { return 1; }
  if (p1U > p2U) { return -1; }
  return 0;
}
const priceAsc = (p1, p2) => {
  if (p1.price < p2.price) { return -1; }
  if (p1.price > p2.price) { return 1; }
  return 0;
}
const priceDesc = (p1, p2) => {
  if (p1.price < p2.price) { return 1; }
  if (p1.price > p2.price) { return -1; }
  return 0;
}

const Products = () => {

    const navigate = useNavigate();
    const { filter } = useContext(ShopContext);
    const [ localFilter, setLocalFilter ] = useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [ sortFunction, setSortFunction ] = useState(() => () => {});
    const open = Boolean(anchorEl);

    function openSortMenu(event) {
      setAnchorEl(event.currentTarget);
    }

    function handleSortSelect (type) {
      if      (type === 'name-asc')   { setSortFunction(() => nameAsc) } 
      else if (type === 'name-desc')  { setSortFunction(() => nameDesc) }
      else if (type === 'price-asc')  { setSortFunction(() => priceAsc) }
      else if (type === 'price-desc') { setSortFunction(() => priceDesc) }
      closeSortMenu();
    }
    function closeSortMenu() {
      setAnchorEl(null);
    }
    
    /*const { isLoading, isError, data } = useQuery(['products'], fetchProducts, { staleTime: Infinity });
    if(isLoading) return <Loading loading backdrop />
    if(isError) return (<div>Error</div>)
    let products = data.data;*/

    return (
        <Box sx={{ display: 'flex',  gap: '20px', flexDirection: 'column', width: '100%' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', width: {xs: '90%', md: '50%'}, margin: '0 auto' }}>
            <TextField fullWidth label="Pesquisar" id="search" onChange={(e) => setLocalFilter(e.target.value.toUpperCase())} />

            <IconButton size='medium' onClick={openSortMenu} >
                <SortIcon fontSize='large' />
            </IconButton>
            <Menu id="sort-menu" anchorEl={anchorEl} open={open} onClose={closeSortMenu} MenuListProps={{ 'aria-labelledby': 'basic-button' }}>
              <MenuItem onClick={() => handleSortSelect('name-asc')}>Nome (A-Z)</MenuItem>
              <MenuItem onClick={() => handleSortSelect('name-desc')}>Nome (Z-A)</MenuItem>
              <MenuItem onClick={() => handleSortSelect('price-asc')}>Preço (+ barato)</MenuItem>
              <MenuItem onClick={() => handleSortSelect('price-desc')}>Preço (+ caro)</MenuItem>
            </Menu>
          </Box>
          
          <Grid container spacing={{ xs: 2, sm: 2, md: 2, xl: 3 }} columns={{ xs: 1, sm: 2, md: 3, xl: 4 }}>
            {products.filter((product) => {
                return ( (filter === null && localFilter === null) || 
                  product.categoryName === filter || product.subCategoryName === filter ||
                  product.name.toUpperCase().includes(localFilter));
            })
            .sort((a, b) => sortFunction(a, b))
            .map((product) => {
                return (
                  <Grid item xs={1} sm={1} md={1} xl={1} key={product.id}>
                    <Product key={product.id} data={product} clickHandler={() => navigate("/products/"+product.id)} />
                  </Grid>
                )
            })}
          </Grid>
        </Box>
    );
  };
  
  export default Products;