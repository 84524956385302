import Product from '../components/Product';
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { ShopContext } from "../context/shop-context";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";
import { fetchProducts } from "../api/requests";
import Loading from '../components/Loading';
import products from "../api/products.json";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const Home = () => {

    const navigate = useNavigate();
    const { filter } = useContext(ShopContext);

    /*const { isLoading, isError, data } = useQuery(['products'], fetchProducts, { staleTime: Infinity });
    if(isLoading) return <Loading loading backdrop />
    if(isError) return (<div>Error</div>)
    let products = data.data;*/

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '20px' }}>
        <Card sx={{ width: {xs: '90%', md: '100%'}, margin: '0 auto', textAlign: 'center', cursor: 'pointer', backgroundImage: 'linear-gradient(to bottom right, #1976d2, #6676d2)' }} onClick={() => navigate('/products')}>
          <CardContent sx={{ height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}>
              <Typography gutterBottom variant="h5" component="div">
                  Anúncios
              </Typography>
          </CardContent>
        </Card>

        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row'}, alignItems: {xs: 'center', md: 'flex-start'}, gap: '20px' }}>
          <Card sx={{ width: '90%', textAlign: 'center', cursor: 'pointer', backgroundImage: 'linear-gradient(to bottom right, #1976d2, #6676d2)' }} onClick={() => navigate('/products')}>
            <CardContent sx={{ height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}>
                <Typography gutterBottom variant="h5" component="div">
                    Promoções
                </Typography>
            </CardContent>
          </Card>

          <Card sx={{ width: '90%', textAlign: 'center', cursor: 'pointer', backgroundImage: 'linear-gradient(to bottom right, #1976d2, #6676d2)' }} onClick={() => navigate('/products')}>
            <CardContent sx={{ height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}>
                <Typography gutterBottom variant="h5" component="div">
                    Mais vendidos
                </Typography>
            </CardContent>
          </Card>

          <Card sx={{ width: '90%', textAlign: 'center', cursor: 'pointer', backgroundImage: 'linear-gradient(to bottom right, #1976d2, #6676d2)' }} onClick={() => navigate('/products')}>
            <CardContent sx={{ height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}>
                <Typography gutterBottom variant="h5" component="div">
                    Todos
                </Typography>
            </CardContent>
          </Card>
        </Box>
        <Card sx={{ width: {xs: '90%', md: '100%'}, margin: '0 auto', textAlign: 'center', cursor: 'pointer', backgroundImage: 'linear-gradient(to bottom right, #1976d2, #6676d2)' }} onClick={() => navigate('/products')}>
          <CardContent sx={{ height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}>
              <Typography gutterBottom variant="h5" component="div">
                  Anúncios
              </Typography>
          </CardContent>
        </Card>
      </Box>
        


    );
  };
  
  export default Home;