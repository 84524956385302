import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Box from "@mui/material/Box";

const CartItem = ({ data, decreaseAction, increaseAction }) => {

    const { name, price, imageUrl, count } = data;
    
    return (
        <Paper elevation={0} sx={{ textAlign: 'center', cursor: 'pointer', minWidth: 200 }} >  
            <img src={imageUrl ? imageUrl : 'https://products-folder.s3.amazonaws.com/public/product-placeholder.webp'} alt={name}
                style={{ objectFit: 'scale-down', maxWidth: 200, height: 200 }} />
            <Typography variant="h6" color="text.secondary">
                {price * count} &#8364;
            </Typography>
            <Typography gutterBottom variant="h5" component="div">
              {count}x  {name}
            </Typography>
            <Box>
                <Button onClick={increaseAction}> <AddIcon /> </Button>
                <Button onClick={decreaseAction}> <RemoveIcon /> </Button>
            </Box>
        </Paper>
    );
};

export default CartItem;