import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Card from "@mui/material/Card";
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const Management = () => {
    const navigate = useNavigate();

    return (
        <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
            <Grid container spacing={{ xs: 1, sm: 1, md: 2 }} columns={{ xs: 1, sm: 3, md: 12 }}>
                <Grid item xs={1} sm={1} md={2}>
                    <Card sx={{ cursor: 'pointer', height: '100%' }} onClick={() => navigate("/management/categories")}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Gerir Categorias
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={1} sm={1} md={2}>
                    <Card sx={{ cursor: 'pointer', height: '100%' }} onClick={() => navigate("/management/products")}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Gerir Produtos
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
  };
  
  export default Management;