import React, { useContext } from "react";
import { ShopContext } from "../context/shop-context";
import { useNavigate } from "react-router-dom";
import CartItem from "../components/CartItem";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';

const Cart = () => {
  const { cartItems, getTotalCartAmount, checkout, addToCart, removeFromCart } = useContext(ShopContext);
  const totalAmount = getTotalCartAmount();

  const navigate = useNavigate();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
      <Typography variant="h3">  O seu carrinho </Typography>
      <Box>
        {Object.keys(cartItems).map(key => 
          <CartItem key={cartItems[key].id} data={cartItems[key]} decreaseAction={() => removeFromCart(cartItems[key])} increaseAction={() => addToCart(cartItems[key])}/> )}
      </Box>

      {totalAmount > 0 ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <Typography variant="h4">Total: {totalAmount} €  </Typography>
          <Button variant="outlined" size="medium" onClick={() => navigate("/")}>
              Continuar a comprar
          </Button>
          <Button variant="outlined" size="medium" onClick={() => { checkout(); navigate("/checkout")}} >
              Checkout
          </Button>
        </Box>
      ) : (
        <Typography variant="h5"> O carrinho está vazio </Typography>
      )}
    </Box>
  );
};

export default Cart;