import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from '../routes/Home';
import Products from '../routes/Products';
import ProductDetails from '../routes/ProductDetails';
import Cart from '../routes/Cart';
import Management from '../routes/Management';
import Header from '../components/Header';
import Footer from '../components/Footer';
import PrivacyPolicy from './static/PrivacyPolicy';
import CookiePolicy from './static/CookiePolicy';
import Contacts from './static/Contacts';
import Checkout from '../routes/Checkout';
import ConditionsOfUse from './static/ConditionsOfUse';
import PersonalDataPolicy from './static/PersonalDataPolicy';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ShopContextProvider } from '../context/shop-context';
import ProtectedRoute from '../routes/ProtectedRoute';
import ManagementProducts from '../management/ManagementProducts';
import ManagementProductDetails from '../management/ManagementProductDetails';
import ManagementCategories from '../management/ManagementCategories';
import ManagementCategoryDetails from '../management/ManagementCategoryDetails';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import * as locales from '@mui/material/locale';
import * as React from 'react';

const App = () => {

  const theme = useTheme();
  theme.typography = {
    fontFamily: 'monospace'
  };

  theme.typography.h5 = {
    fontSize: '1.2rem',
    '@media (min-width:600px)': {
      fontSize: '1.2rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2rem',
    },
  };
  const themeWithLocale = React.useMemo(
    () => createTheme(theme, locales['ptPT']), [theme]
  );

  

  const client = new QueryClient();

  return (

    <QueryClientProvider client={client}>
      <ThemeProvider theme={themeWithLocale}>
        <ShopContextProvider>
          <BrowserRouter>
            <Header />
            <main className="content">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/products" element={<Products />} />
                <Route path="/products/:productIdStr" element={<ProductDetails />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/cookie-policy" element={<CookiePolicy />} />
                <Route path="/contacts" element={<Contacts />} />
                <Route path="/conditions-of-use" element={<ConditionsOfUse />} />
                <Route path="/personal-data-policy" element={<PersonalDataPolicy />} />
                <Route element={<ProtectedRoute />} >
                    <Route path="/management" element={<Management />} />
                    <Route path="/management/products" element={<ManagementProducts />} />
                    <Route path="/management/products/:productIdStr" element={<ManagementProductDetails />} />
                    <Route path="/management/categories" element={<ManagementCategories />} />
                    <Route path="/management/categories/:categoryIdStr" element={<ManagementCategoryDetails />} />
                </Route>
              </Routes>
            </main>
            <Footer />
          </BrowserRouter>
        </ShopContextProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;