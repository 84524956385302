import { useQuery, useQueryClient } from "@tanstack/react-query";
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useParams, useNavigate } from "react-router-dom";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { fetchManagementCategories, postSubCategory } from "../api/requests";
import Loading from "../components/Loading";
import { useState } from "react";
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const ManagementCategoryDetails = () => {

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { categoryIdStr } = useParams();
  const categoryId = Number(categoryIdStr);
  const [open, setOpen] = React.useState(false); // dialog
  const [loading, setLoading] = useState(false); // spinning bar
  const [alert, setAlert] = useState({show: false, severity: 'sucess', message: ''}); // feedback
  const [subCategoryToEdit, setSubCategory] = useState({id: '', name: '', visible: false});
  const { isLoading, isError, data } = useQuery(['management_categories'], fetchManagementCategories);

  if(isLoading) return <Loading loading backdrop />
  if(isError) return (<div>Error</div>)

  const category = data.find((category) => category.id === categoryId);

  const handleClickOpen = (subCategory) => {
    setSubCategory(subCategory);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    updateSubCategory(subCategoryToEdit);
    setOpen(false);
  };

  const updateSubCategory = async ({id, name, visible}) => {
    setLoading(true);
    postSubCategory({id, name, visible, categoryId: category.id})
      .then( _ => {
        setLoading(false);
        setAlert({show: true, severity: 'success', message: 'Subcategoria atualizada com sucesso'});
        queryClient.invalidateQueries(['management_categories'], {exact: true });
      })
      .catch( _ => {
        setLoading(false);
        setAlert({show: true, severity: 'error', message: 'Erro ao atualizar a subcategoria'});
      });
  }

  return (
    <div>
      <Loading loading={loading} backdrop />
      {alert.show ? <Alert severity={alert.severity} icon={<CheckIcon fontSize="inherit" />} onClose={() => setAlert({show: false, severity: 'sucess', message: ''})}> {alert.message} </Alert> : <></> }
      <Button onClick={() => navigate('/management/categories')}>Voltar</Button>
      <Button onClick={() => handleClickOpen({id: null, name: '', visible: false})}>Adicionar Subcategoria</Button>
      <Button onClick={() => queryClient.invalidateQueries({  queryKey: ['management_categories'], exact: true })}>Atualizar</Button>
      <TableContainer component={Paper} >
        <Card sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
          <CardContent>
            <h2>Categoria {category.id} - {category.name}</h2>
            <h5>{category.visible ? '' : '(Escondida)'}</h5>
          </CardContent>
        </Card>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell> ID </TableCell>
              <TableCell> Nome </TableCell>
              <TableCell> Visível </TableCell>
              <TableCell> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {category.subCategories.filter( subcategory => subcategory.id !== 0).map(subcategory => (
              <TableRow key={subcategory.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell> {subcategory.id} </TableCell>
                <TableCell> {subcategory.name} </TableCell>
                <TableCell> {subcategory.visible ? 'Sim' : 'Não'} </TableCell>
                <TableCell> 
                  <Button variant="outlined" onClick={() => handleClickOpen(subcategory)}>
                    Editar
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{subCategoryToEdit.id === null ? 'Nova Subcategoria' : 'Editar Subcategoria'}</DialogTitle>
        <DialogContent>
          <FormGroup>
            <TextField onChange={(event) => { setSubCategory({...subCategoryToEdit, name: event.target.value}) }}
                autoFocus margin="dense" id="name" label="Nome" type="text" fullWidth variant="standard" defaultValue={subCategoryToEdit.name} />
            <FormControlLabel 
                control={<Checkbox checked={subCategoryToEdit.visible} onChange={(event) => setSubCategory({...subCategoryToEdit, visible: event.target.checked})  } />} 
                label="Visível" />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleSave}>Guardar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ManagementCategoryDetails;



