import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { ShopContext } from "../context/shop-context";

const ShoppingCart = () => {

    const { getTotalCartNumber } = useContext(ShopContext);
    const totalNumber = getTotalCartNumber();

    return (
        <div className="shopping-cart">
            <div>
                <Link to="/cart">
                    <FontAwesomeIcon icon={faShoppingCart} />
                </Link>
                <span>{totalNumber}</span>
            </div>
        </div>
    );
  };
  
  export default ShoppingCart;