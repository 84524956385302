import { useNavigate } from 'react-router-dom';
import ShoppingCart from './ShoppingCart';
import { useContext } from "react";
import { ShopContext } from "../context/shop-context";
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import AdbIcon from '@mui/icons-material/Adb';
import { fetchCategories } from '../api/requests';
import Loading from './Loading';
import { useQuery } from "@tanstack/react-query";
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import categories from "../api/categories.json";
import MenuItem from './MenuItem';


const Header = () => {

    const navigate = useNavigate();
    const { handleFilterChange } = useContext(ShopContext);
    const [ drawer, setDrawer ] = React.useState(false);

    /*const { isLoading, isError, data } = useQuery(['categories'], fetchCategories, { staleTime: Infinity } );
    if(isLoading) return <Loading loading />
    if(isError) return (<div>Error</div>)
    let categories = data.data*/

    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar position="static" sx={{ boxShadow: 'none' }}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>

                        <Box onClick={() => { handleFilterChange(null); navigate('/'); }} sx={{ cursor: 'pointer', flexGrow: 1, display: { xs: 'none', md: 'flex' }, position: 'relative' }}>
                            <img style={{ maxWidth: '50px' }} src={require('../bc-simple.png')}></img>
                        </Box>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, position: 'relative' }}>
                            <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={() => setDrawer(!drawer)} color="inherit" >
                                <MenuIcon />
                            </IconButton>
                        </Box>

                        <Box onClick={() => { handleFilterChange(null); navigate('/'); }} sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, position: 'relative' }}>
                            <img style={{ maxWidth: '50px' }} src={require('../bc-simple.png')}></img>
                        </Box>

                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {categories.map( category => <MenuItem key={category.id} data={category} /> )}
                        </Box>

                        <Box sx={{ flexGrow: 0 }}>
                            <ShoppingCart />
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <Box component="nav">
                <Drawer anchor="top" open={drawer} onClose={() => setDrawer(false)}
                    sx={{ display: { xs: 'block', sm: 'block' } }}>
                    <Box sx={{ margin: '0 auto' }} role="presentation" onKeyDown={() => setDrawer(false)}>
                        <List>
                            {categories.map((category) => (
                                <ListItem key={category.id} disablePadding>
                                    <ListItemButton onClick={() => {handleFilterChange(category.name); navigate('/products'); setDrawer(false);}}>
                                        <ListItemText primary={category.name} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Drawer>
            </Box>
        </Box>
    );
};

export default Header;