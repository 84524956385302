import Backdrop from '@mui/material/Backdrop';
import CircularProgress from "@mui/material/CircularProgress";
import Box from '@mui/material/Box';

const Loading = ({loading, backdrop}) => {


    return ( 
        backdrop ? 
            (<Backdrop open={loading && loading}>
                <CircularProgress sx={{ display: 'flex' }} />
            </Backdrop>) 
            :  
            (loading && loading ? 
                (<Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress  />
                </Box>)
                :
                (<></>)
            )
    )
}

export default Loading;