import { useQuery, useQueryClient } from "@tanstack/react-query";
import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import { useState, useEffect } from "react";
import { Storage } from 'aws-amplify';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DeleteIcon from '@mui/icons-material/Delete';
import Loading from "../components/Loading";
import { fetchManagementCategories, fetchManagementProducts, postProduct, postProductRelated, delProduct } from "../api/requests";
import { useParams, useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const ManagementProductDetails = () => {

  const { productIdStr } = useParams();
  const navigate = useNavigate();
  const productId = Number(productIdStr);
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({show: false, severity: 'sucess', message: ''});
  const [error, setError] = useState({
      error: false, 
      errorMessage: {
        name: null,
        price: null,
        subCategoryId: null,
      }
    });
  const [images, setImages] = useState([]);
  const [product, setProduct] = useState({name: '', price: '', imageUrl: '', subCategoryId: '', related: []});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchProductImages(productId);
  }, []);

  const productsResponse = useQuery(['management_products'], fetchManagementProducts, { staleTime: Infinity } );
  useEffect(() => {
    if (productsResponse.status === 'success') {
      setProduct(productsResponse.data.find((product) => product.id === productId));
    }
  }, [productsResponse.status, productsResponse.data]);
  const categoriesResponse = useQuery(['management_categories'], fetchManagementCategories, {staleTime: Infinity});
  if(productsResponse.isLoading) return <Loading loading backdrop />
  if(productsResponse.isError) return (<div>Error</div>)
  if(categoriesResponse.isLoading) return <Loading loading backdrop />;
  if(categoriesResponse.isError) return (<div>Error</div>)
  let subCategories = [];
  for(const category of categoriesResponse.data) {
    for(const subcategory of category.subCategories) {
      if(subcategory.id !== 0) {
        subCategories.push(subcategory);
      }
    }
  }

  async function fetchProductImages(id) {
    Storage.list( (id + '/') )
      .then(({ results }) => {
        setImages(results);
      });
  }

  async function uploadProductImage(event) {
    const file = event.target.files[0];
    const path = product.id + '/' + file.name;
    Storage.put(path, file, { contentType: "image/png" })
        .then(_ => {
          fetchProductImages(product.id);
        });
  }

  async function deleteProductImage(key) {
    Storage.remove(key)
        .then(_ => {
          setImages(images.filter(image => image.key !== key));
        });
  }

  async function updateProduct (product) {
    setLoading(true);
    if(product.id !== null && images && images.length === 1 && product.imageUrl === '') {
      product.imageUrl = 'https://products-folder.s3.amazonaws.com/public/'+images[0].key;
    }
    postProduct(product)
      .then( _ => postProductRelated( {id: product.id, related: product.related} ))
      .then( _ => {
        setLoading(false);
        setAlert({show: true, severity: 'success', message: 'Produto atualizado com sucesso'});
        queryClient.invalidateQueries(['management_products'], {exact: true });
      })
      .catch( _ => {
        setLoading(false);
        setAlert({show: true, severity: 'error', message: 'Erro ao atualizar o produto'});
      });
  }

  const validateForm = () => {
    let valid = true;

    if(product.name === '' || product.price === '' || product.subCategoryId === '') {
      valid = false;
    }

    setError({...error, errorMessage: 
      {...error.errorMessage, 
        name: product.name === '' ? 'Nome é obrigatório' : null,
        price: product.price === '' ? 'Preço é obrigatório' : null,
        subCategoryId: product.subCategoryId === '' ? 'Sub Categoria é obrigatória' : null,
      }});

    return valid;
  }

  const handleSave = () => {
    if(validateForm()) {
      updateProduct(product);
    }
  };

  const handleDelete = () => {
    delProduct(product.id)
      .then(_ => {
        queryClient.invalidateQueries(['management_products'], {exact: true });
        setOpen(false);
        navigate('/management/products');
      })
      .catch(_ => {

      })
  };

  return (

    <Box sx={{ flexGrow: 1 }}>
      <Loading loading={loading} backdrop />
      { alert.show ? <Alert severity={alert.severity} icon={<CheckIcon fontSize="inherit" />} onClose={() => setAlert({show: false, severity: 'sucess', message: ''})}> {alert.message} </Alert> : <></> }
      <Button onClick={() => navigate('/management/products')}>Voltar</Button>
      <Button onClick={() => queryClient.invalidateQueries({  queryKey: ['management_products'], exact: true })}>Atualizar</Button>
      <Button type="submit" onClick={handleSave}> Guardar </Button>
      <Button onClick={() => setOpen(true)}> Apagar </Button>
      <FormGroup sx={{ gap: '20px' }}>
        <TextField onChange={ e => setProduct({...product, name: e.target.value}) }
            error={!!error.errorMessage.name}
            helperText={error.errorMessage.name}
            autoFocus required margin="dense" id="name" label="Nome" type="text" fullWidth variant="standard" value={product.name} />
        <TextField onChange={ e => setProduct({...product, price: e.target.value}) }
            error={!!error.errorMessage.price}
            helperText={error.errorMessage.price}
            autoFocus required margin="dense" id="price" label="Preço" type="number" fullWidth variant="standard" value={product.price} />
        <FormControl sx={{ margin: '20px 0px' }} error={!!error.errorMessage.subCategoryId} >
          <InputLabel id="label-sub-category-id">Sub Categoria</InputLabel>
          <Select
            labelId="label-sub-category-id" id="demo-simple-select" value={product.subCategoryId} label="Sub Categoria"
            required onChange={ e => setProduct({...product, subCategoryId: e.target.value}) }>
              {subCategories.map( (subCategory) => {
                return <MenuItem key={subCategory.id} value={subCategory.id}>{subCategory.name}</MenuItem>
              })}
          </Select>
          {!!error.errorMessage.subCategoryId && <FormHelperText>{error.errorMessage.subCategoryId}</FormHelperText>}
        </FormControl>

        <Box>
          <Typography variant="h6"> Imagens </Typography>
          <Input type="file" onChange={uploadProductImage}> Adicionar imagens </Input>
          <ImageList sx={{ overflowX: 'auto', display: 'flex'}} rowHeight={200}>
            {images.map((item) => (
              <ImageListItem key={item.key} sx={{ display: 'flex', flexDirection: 'row'}}>
                <img src={`https://products-folder.s3.amazonaws.com/public/${item.key}`} alt={item.key} loading="lazy" />
                <ImageListItemBar
                  subtitle={item.key.split('/')[1]}
                  actionIcon={
                    <>
                    <IconButton
                      sx={{ color: (product.imageUrl === `https://products-folder.s3.amazonaws.com/public/${item.key}` ? 'yellow' : 'rgba(255, 255, 255, 0.54)') }}
                      aria-label={`info about ${item.key}`}
                      onClick={() => { setProduct({...product, imageUrl: `https://products-folder.s3.amazonaws.com/public/${item.key}`}) }}>
                      <FavoriteIcon />
                    </IconButton>
                    <IconButton sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                      onClick={() => deleteProductImage(item.key)} >
                      <DeleteIcon/>
                    </IconButton>
                    </>
                  }
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Box>

        <Box>
          <Typography variant="h6"> Produtos Relacionados </Typography>
          <FormControl >
            <Select id="related-ids" required multiple value={product.related}
              onChange={ e => setProduct({...product, related: e.target.value}) }>
                {productsResponse.data.sort( (p1, p2) => {
                  const p1U = p1.name.toUpperCase(); // ignore upper and lowercase
                  const p2U = p2.name.toUpperCase(); // ignore upper and lowercase
                  if (p1U < p2U) { return -1; }
                  if (p1U > p2U) { return 1; }
                  return 0;
                }).map( (p) => {
                  return <MenuItem key={p.id} value={p.id}>{p.name}</MenuItem>
                })}
            </Select>
          </FormControl>
        </Box>
      </FormGroup>
      <MyDialog open={open} handleClose={() => setOpen(false)} handleOk={handleDelete} />
    </Box>
  );
    
};

function MyDialog( {open, handleClose, handleOk} ) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Confirmar</DialogTitle>
      <DialogContent>
        Tens a certeza que queres apagar? Vai dar barraco
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button onClick={handleOk}>Confirmar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ManagementProductDetails;



