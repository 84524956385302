import React, { useContext } from "react";
import { ShopContext } from "../context/shop-context";
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';


const Product = ({ data, clickHandler }) => {

    const { id, name, price, imageUrl, categoryId, categoryName, subCategoryId, subCategoryName } = data
    const { addToCart, cartItems } = useContext(ShopContext);
    const cartItemCount = cartItems[id] ? cartItems[id].count : 0;
    

    return (
        <Paper elevation={0} sx={{ textAlign: 'center', cursor: 'pointer', minWidth: 200 }} onClick={clickHandler}>  
            <img src={imageUrl ? imageUrl : 'https://products-folder.s3.amazonaws.com/public/product-placeholder.webp'} 
                style={{ objectFit: 'scale-down', maxWidth: 200, height: 200 }} />
            <Typography variant="h6" color="text.secondary">
                {price} &#8364;
            </Typography>
            <Typography gutterBottom variant="h5" component="div">
                {name}
            </Typography>
        </Paper>

        /*<Card sx={{ textAlign: 'center', cursor: 'pointer', height: '100%' }} onClick={clickHandler}>
            <CardMedia
                sx={{ height: 150, objectFit: 'scale-down' }}
                component="img"
                alt="Product image"
                image={imageUrl}
            />
            <CardContent>
                <Typography variant="h6" color="text.secondary">
                    {price} &#8364;
                </Typography>
                <Typography gutterBottom variant="h5" component="div">
                    {name}
                </Typography>
            </CardContent>
        </Card>*/
    );
};

/*<CardActions>
<Button size="small" onClick={() => addToCart({ id, name, price, imageUrl })}>Adicionar ao Carrinho {cartItemCount > 0 && <> ({cartItemCount})</>}</Button>
</CardActions>*/

export default Product;