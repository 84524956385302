import React from 'react';
import { Outlet } from 'react-router-dom';
import { Auth, Hub } from 'aws-amplify';
import { useEffect, useState } from 'react';

const ProtectedRoute = () => {

    // should use context
    const [loggedIn, setLoggedIn] = useState(false);

    // Check is done only for the first time, then checks locally
    useEffect(() => {
        Auth.currentAuthenticatedUser({ bypassCache: false })
        .then((user) => { setLoggedIn(true); })
        .catch((err) => { Auth.federatedSignIn(); });
    }, [loggedIn]);

    // Listen from Cognito UI redirect
    useEffect(() => {
        Hub.listen('auth', ({ payload: { event, data } }) => {
            switch (event) {
                case 'signIn':
                case 'cognitoHostedUI':
                case 'signIn_failure':
                case 'cognitoHostedUI_failure':
                default:
                    break;
            }
        });
      });

    return <Outlet />;
};

export default ProtectedRoute;