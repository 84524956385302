import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import { Amplify } from 'aws-amplify';

// styles
import './App.css';


Amplify.configure({
    Auth: {
      identityPoolId: 'us-east-1:7f596f0f-0073-4b96-9d48-3b971b5755ec',
      region: 'us-east-1',
      userPoolId: 'us-east-1_k9U1QQhR4',
      userPoolWebClientId: '5i2vqrcgjch5chcoj3u95chgjv',
      mandatorySignIn: false,
      oauth: {
        domain: 'ecommerce-management.auth.us-east-1.amazoncognito.com',
        scope: ['email', 'openid', 'phone'],
        redirectSignIn: 'https://bc-banho-construcao.com/management',
        redirectSignOut: 'https://bc-banho-construcao.com',
        clientId: '5i2vqrcgjch5chcoj3u95chgjv',
        responseType: 'code',
      },
    },
    API: {
      endpoints: [
        {
          name: "management",
          endpoint: "https://c4ausknyrc.execute-api.us-east-1.amazonaws.com/stg"
        }
      ]
    },
    Storage: {
        AWSS3: {
            bucket: 'products-folder',
            region: 'us-east-1',
        }
    }
  });



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
          <App />
    </React.StrictMode>
);