import { useQuery, useQueryClient } from "@tanstack/react-query";
import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import { useState } from "react";
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ProductManagement from "./ProductManagement";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Loading from "../components/Loading";
import { useNavigate } from 'react-router-dom';
import { fetchManagementCategories, fetchManagementProducts, postProduct } from "../api/requests";

const ManagementProducts = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [newProduct, setProduct] = useState({name: '', price: '', imageUrl: '', subCategoryId: ''});
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({show: false, severity: 'sucess', message: ''});
  const [error, setError] = useState({
      error: false, 
      errorMessage: {
        name: null,
        price: null,
        subCategoryId: null,
      }
    });
  const [images, setImages] = useState([]);

  const { isLoading, isError, data } = useQuery(['management_products'], fetchManagementProducts, {staleTime: Infinity});
  const categoriesResponse = useQuery(['management_categories'], fetchManagementCategories, {staleTime: Infinity});

  if(isLoading) return <Loading loading backdrop />
  if(isError) return (<div>Error</div>)
  if(categoriesResponse.isLoading) return <Loading loading backdrop />;
  if(categoriesResponse.isError) return (<div>Error</div>)

  let subCategories = [];
  for(const category of categoriesResponse.data) {
    for(const subcategory of category.subCategories) {
      if(subcategory.id !== 0) {
        subCategories.push(subcategory);
      }
    }
  }

  let products = data;

  async function updateProduct (product) {
    setLoading(true);
    if(product.id !== null && images && images.length === 1 && product.imageUrl === '') {
      product.imageUrl = 'https://products-folder.s3.amazonaws.com/public/'+images[0].key;
    }
    postProduct(product)
      .then( _ => {
        setLoading(false);
        setAlert({show: true, severity: 'success', message: `Produto ${product.id === '' ? 'criado' : 'atualizado'} com sucesso`});
        queryClient.invalidateQueries(['management_products'], {exact: true });
      })
      .catch( _ => {
        setLoading(false);
        setAlert({show: true, severity: 'error', message: `Erro ao ${product.id === '' ? 'criar' : 'atualizar'} o produto`});
      });
  }

  const handleClose = () => {
    setOpen(false);
    setProduct({name: '', price: '', imageUrl: '', subCategoryId: ''});
    setImages([]);
  };

  function handleClickOpen (product) {
    setProduct(product);
    setOpen(true);
  };

  const validateForm = () => {
    let valid = true;

    if(newProduct.name === '' || newProduct.price === '' || newProduct.subCategoryId === '') {
      valid = false;
    }

    setError({...error, errorMessage: 
      {...error.errorMessage, 
        name: newProduct.name === '' ? 'Nome é obrigatório' : null,
        price: newProduct.price === '' ? 'Preço é obrigatório' : null,
        subCategoryId: newProduct.subCategoryId === '' ? 'Sub Categoria é obrigatória' : null,
      }});

    return valid;
  }

  const handleSave = () => {
    if(validateForm()) {
      updateProduct(newProduct);
      setOpen(false);
    }
  };

  return (

    <Box sx={{ flexGrow: 1 }}>
      <Loading loading={loading} backdrop />
      { alert.show ? <Alert severity={alert.severity} icon={<CheckIcon fontSize="inherit" />} onClose={() => setAlert({show: false, severity: 'sucess', message: ''})}> {alert.message} </Alert> : <></> }
      <Button onClick={() => navigate('/management')}>Voltar</Button>
      <Button onClick={() => handleClickOpen({id: '', name: '', price: '', imageUrl: '', subCategoryId: ''})}>Adicionar Produto</Button>
      <Button onClick={() => queryClient.invalidateQueries({  queryKey: ['management_products'], exact: true })}>Atualizar</Button>
      <Grid container spacing={{ xs: 1, sm: 1, md: 2 }} columns={{ xs: 1, sm: 3, md: 12 }}>
          {products.sort( (p1, p2) => {
                  const p1U = p1.name.toUpperCase(); // ignore upper and lowercase
                  const p2U = p2.name.toUpperCase(); // ignore upper and lowercase
                  if (p1U < p2U) { return -1; }
                  if (p1U > p2U) { return 1; }
                  return 0;
                }).map((product) => {
                return (
                  <Grid item xs={1} sm={1} md={2} key={product.id}>
                   <ProductManagement key={product.id} data={product} clickHandler={() => navigate('/management/products/'+product.id)} />
                </Grid>
              )
            })}
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Novo Produto</DialogTitle>
        <DialogContent>
          <FormGroup>
            <TextField onChange={(event) => { setProduct({...newProduct, name: event.target.value}) }}
                error={!!error.errorMessage.name}
                helperText={error.errorMessage.name}
                autoFocus required margin="dense" id="name" label="Nome" type="text" fullWidth variant="standard" value={newProduct.name} />
            <TextField onChange={(event) => { setProduct({...newProduct, price: event.target.value}) }}
                error={!!error.errorMessage.price}
                helperText={error.errorMessage.price}
                autoFocus required margin="dense" id="price" label="Preço" type="number" fullWidth variant="standard" value={newProduct.price} />
            <FormControl sx={{ margin: '20px 0px' }} error={!!error.errorMessage.subCategoryId} >
              <InputLabel id="label-sub-category-id">Sub Categoria</InputLabel>
              <Select
                labelId="label-sub-category-id"
                id="demo-simple-select"
                value={newProduct.subCategoryId}
                label="Sub Categoria"
                required
                onChange={(event) => { setProduct({...newProduct, subCategoryId: event.target.value}) }}>
                  {subCategories.map( (subCategory) => {
                    return <MenuItem key={subCategory.id} value={subCategory.id}>{subCategory.name}</MenuItem>
                  })}
              </Select>
              {!!error.errorMessage.subCategoryId && <FormHelperText>{error.errorMessage.subCategoryId}</FormHelperText>}
            </FormControl>
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button type="submit" onClick={handleSave}>Criar</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
    
};

export default ManagementProducts;



