import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { ShopContext } from "../context/shop-context";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useParams } from "react-router-dom";
import { fetchProducts } from '../api/requests';
import Loading from '../components/Loading';
import Button from '@mui/material/Button';
import BreadCrumb from '../components/BreadCrumb';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Storage } from 'aws-amplify';
import SwipeableViews from 'react-swipeable-views';
import React from 'react';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import products from "../api/products.json";

const ProductDetails = () => {


    const { productIdStr } = useParams();
    const productId = Number(productIdStr);
    const { addToCart, cartItems } = useContext(ShopContext);
    const [ loadingImages, setLoadingImages] = useState(false);
    const [ images, setImages ] = useState([]);
    const [ activeStep, setActiveStep] = React.useState(0);
    const [ product, setProduct] = useState(products.find(product => product.id === productId));
    const maxSteps = images.length;

    useEffect(() => {
      fetchProductImages(productId);
    }, []);

    /*const { isLoading, isError, data } = useQuery(['products'], fetchProducts, { staleTime: Infinity } );
    if(isLoading) return <Loading loading backdrop />
    if(isError) return (<div>Error</div>)
    let product = data.data.find((product) => product.id === productId);*/

    async function fetchProductImages(productId) {
      setLoadingImages(true);
      Storage.list( (productId + '/'), { pageSize : 'ALL' } )
        .then(({ results }) => {
          setLoadingImages(false);
          setImages(results);
        }) 
        .catch(_ => setLoadingImages(false));
    }

    const handleStepChange = (step) => {
      setProduct({...product, imageUrl: `https://products-folder.s3.amazonaws.com/public/${images[step].key}`});
      setActiveStep(step);
    };

    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
          <Box sx={{ flexGrow: 1 }}>
            <BreadCrumb crumbs={[product.subCategoryName, product.categoryName]} />

            <Grid container spacing={{ xs: 2, sm: 2, md: 2, xl: 3 }} columns={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2 }}>
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                <Paper elevation={0} sx={{ textAlign: 'center', cursor: 'pointer', minWidth: 200, maxWidth: 400, margin: 'auto' }}>  
                    <img style={{ maxWidth: '100%', height: 400 }} src={product.imageUrl ? product.imageUrl : 'https://products-folder.s3.amazonaws.com/public/product-placeholder.webp'} />
                </Paper>
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                <Paper elevation={0} 
                    sx={{ textAlign: {xs: 'center', md:'left'}, cursor: 'pointer', minWidth: 200, position: {md:'relative'}, height:{md:'100%'} }}>  
                  <Typography variant="h4" color="text.secondary">
                      {product.price} &#8364;
                  </Typography>
                  <Typography gutterBottom variant="h5" component="div">
                      {product.name}
                  </Typography>
                  <Button variant="outlined" size="medium" onClick={() => addToCart(product)} sx={{ position: {md:'absolute'}, bottom: {md:0}, left: {md:0} }}>
                      Adicionar ao Carrinho {cartItems[product.id] && cartItems[product.id].count > 0 && <> ({cartItems[product.id].count})</>}
                  </Button>
                </Paper>
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                {loadingImages ? <Loading loading={loadingImages} />
                :
                <Box sx={{ maxWidth: 400, flexGrow: 1, margin: 'auto' }}>
                  <SwipeableViews
                      axis={'x'} index={activeStep} onChangeIndex={handleStepChange} enableMouseEvents>
                      {images.map((item, index) => (
                        <div key={item.key}>
                          {Math.abs(activeStep - index) <= 2 ? (
                            <Box
                              component="img"
                              sx={{
                                height: 200,
                                display: 'block',
                                width: 200,
                                objectFit: 'scale-down',
                                margin: 'auto'
                              }}
                              src={`https://products-folder.s3.amazonaws.com/public/${item.key}`}
                              alt={item.key}
                            />
                          ) : null}
                        </div>
                      ))}
                    </SwipeableViews>
                    <MobileStepper steps={maxSteps} position="static" activeStep={activeStep} 
                      nextButton={
                        <Button size="small" onClick={handleNext}  disabled={activeStep === maxSteps - 1}>
                          <KeyboardArrowRight sx={{ display: { xs: 'none', md: 'flex'} }} />
                        </Button>
                      }
                      backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                          <KeyboardArrowLeft sx={{ display: { xs: 'none', md: 'flex'} }} />
                        </Button>
                      }
                    />
                </Box>
                }
              </Grid>
            </Grid>
            
        </Box>
       
    );
  };

  /*
  <ImageList
                        sx={{
                          gridAutoFlow: "column",
                          gridTemplateColumns: "repeat(auto-fit, minmax(160px,1fr)) !important",
                          gridAutoColumns: "minmax(160px, 1fr)"
                        }}
                        >
                        {images.map((item) => (
                          <ImageListItem key={item.key}>
                            <img src={`https://products-folder.s3.amazonaws.com/public/${item.key}`} />
                          </ImageListItem>
                        ))}
                    </ImageList>*/
  
  export default ProductDetails;