import React from "react";
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {AccountCircle, PointOfSale } from '@mui/icons-material';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useState } from "react";


const Checkout = () => {

  const [data, setData] = useState({firstname: '', lastname: '', billingAddress: '', deliveryAddress: '', email: '', phone: ''});
  const [error, setError] = useState({
    error: false, 
    errorMessage: {
      firstname: null,
      lastname: null,
      billingAddress: null,
      deliveryAddress: null,
      email: null,
      phone: null,
    }
  });
  const navigate = useNavigate();

  const validateForm = () => {
    let valid = true;

    if(!data.firstname || !data.lastname || !data.billingAddress || !data.deliveryAddress || !data.email || !data.phone) {
      valid = false;
    }

    setError({...error, errorMessage: 
      {...error.errorMessage, 
        firstname: !data.firstname ? 'Nome é obrigatório' : null,
        lastname: !data.lastname ? 'Apelido é obrigatório' : null,
        billingAddress: !data.billingAddress ? 'Morada de faturação é obrigatória' : null,
        deliveryAddress: !data.deliveryAddress ? 'Morada de entrega é obrigatória' : null,
        email: !data.email ? 'Email é obrigatório' : null,
        phone: !data.phone ? 'Contacto é obrigatório' : null,
      }});

    return valid;
  }

  return (

    <Box sx={{ display: "flex", flexDirection: 'column', alignItems: 'center', width: '100%', textAlign: 'center', gap: '5%' }}>

      <Typography variant="h4"> Dados de Faturação </Typography>

      <FormGroup sx={{ gap: '20px' }}>
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
          <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <AccountCircle sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <TextField id="firstname" label="Nome" variant="standard" required value={data.firstname}
                  onChange={ e => setData({...data, firstname: e.target.value})}
                  error={!!error.errorMessage.firstname}
                  helperText={error.errorMessage.firstname} />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <AccountCircle sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <TextField id="lastname" label="Apelido" variant="standard" required value={data.lastname}
                onChange={ e => setData({...data, lastname: e.target.value})}
                error={!!error.errorMessage.lastname}
                helperText={error.errorMessage.lastname} />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <PointOfSale sx={{ color: 'action.active', mr: 1, my: 0.5 }} /> 
          <TextField sx={{ width: '100%'}} id="billing-address" label="Morada de Faturação" variant="standard" required value={data.billingAddress}
                onChange={ e => setData({...data, billingAddress: e.target.value})}
                error={!!error.errorMessage.billingAddress}
                helperText={error.errorMessage.billingAddress} />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <PointOfSale sx={{ color: 'action.active', mr: 1, my: 0.5 }} /> 
          <TextField sx={{ width: '100%'}} id="delivery-address" label="Morada de Entrega" variant="standard" required value={data.deliveryAddress} 
              onChange={ e => setData({...data, deliveryAddress: e.target.value})}
              error={!!error.errorMessage.deliveryAddress}
              helperText={error.errorMessage.deliveryAddress}/>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <AccountCircle sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
          <TextField sx={{ width: '100%'}} id="email" label="Email" variant="standard" required value={data.email}
              onChange={ e => setData({...data, email: e.target.value})}
              error={!!error.errorMessage.email}
              helperText={error.errorMessage.email} />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <AccountCircle sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
          <TextField id="phone" label="Contacto" variant="standard" required value={data.phone}
              onChange={ e => setData({...data, phone: e.target.value})}
              error={!!error.errorMessage.phone}
              helperText={error.errorMessage.phone} />
        </Box>
      </FormGroup>

      <Box sx={{ display: "flex", flexDirection: 'column', gap: '10px', marginTop: '20px'}}>
        <Button variant="outlined" size="medium" onClick={() => navigate('/products')} >
              Voltar
        </Button>
        <Button variant="outlined" size="medium" onClick={ () => {if(validateForm()) { alert('Integração com API de pagamento')}} } >
              Efetuar Pagamento
        </Button>
      </Box>
      
    </Box>

  );
};

export default Checkout;