import { createContext, useState } from "react";

export const ShopContext = createContext(null);

export const ShopContextProvider = (props) => {
  const [cartItems, setCartItems] = useState({});
  const [filter, setFilter] = useState(null);

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  }

  const getTotalCartAmount = () => {
    let totalAmount = 0;
    for (const item in cartItems) {
      if (cartItems[item].count > 0) {
        totalAmount += cartItems[item].count * cartItems[item].price;
      }
    }
    return totalAmount;
  };

  const getTotalCartNumber = () => {
    let totalNumber = 0;
    for (const item in cartItems) {
      totalNumber += cartItems[item].count;
    }
    return totalNumber;
  };

  const addToCart = (product) => {
    setCartItems( prev => ({ 
        ...prev, 
        [product.id]: cartItems[product.id] ? ({ ...prev[product.id], count: prev[product.id].count + 1}) : ({ ...product, count: 1})
      }));
  };

  const removeFromCart = (product) => {
    if(cartItems[product.id])
    {
      if(cartItems[product.id].count === 1) {  // remove from cart
        setCartItems(current => {  
            const { [product.id]: _, ...rest} = current; 
            return rest;  
        });
      }
      else { // decrease number
        setCartItems( prev => ({ 
          ...prev, 
          [product.id]: { ...prev[product.id], count: prev[product.id].count - 1}
        }));
      }
    }
  };

  const updateCartItemCount = (newAmount, itemId) => {
    setCartItems((prev) => ({ ...prev, [itemId]: newAmount }));
  };

  const checkout = () => {
    // setCartItems({});
    return true;
  };

  const contextValue = {
    cartItems,
    addToCart,
    updateCartItemCount,
    removeFromCart,
    getTotalCartAmount,
    getTotalCartNumber,
    handleFilterChange,
    filter,
    checkout,
  };

  return (
    <ShopContext.Provider value={contextValue}>
      {props.children}
    </ShopContext.Provider>
  );
};